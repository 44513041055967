import styled from 'styled-components';
import { xs, sm, md } from 'helpers/device';

export const HomeWrapper = styled.div`
    max-width: 960px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
    .list-group{
      margin:0px;
    }
    .header-section {
      background: url(../images/header-bg.jpg) bottom left repeat-x;
      min-height: 120px;
      padding: 0 15px;
      border-bottom: 1px solid #ccc;
      padding-right: 0px;
      padding-left: 0px;
  }
.body-text{
  background-color: #fff;
    box-shadow: 0px 5px 10px #ccc;
    min-height: 550px;
    padding: 15px;
    margin:15px;
}
.gallery{
  display:flex;
  order:4;
}
.gallery>div>img{
  
  padding: 0 8px;
}
.gallery>div>img{
  width: 800px;
    height: 100px;
}
}
a {
  color: #337ab7;
  text-decoration: none;
  background-color: transparent;
}
h2 {
  color: #2e308f;
  margin-bottom: 20px;
  margin-bottom: 20px;
  font-size: 30px;
}
p {
  margin: 0 0 10px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
img {
  vertical-align: middle;
  border: 0;
}
@media (max-width: 968px)
.banner-slid-pic img {
    width: 100%;
}
#primary-slider .item {
  background-color: #2e308f;
  color: #fff;
}
#primary-slider .item .slider-caption h2 {
  font-family: new century schoolbook;
  font-weight: normal;
  color: #fff;
}
h4{
  font-size: 18px;  
}
.services-block .services-img {
  background-color:rgb(236,236,236);
  padding: 15px;
  padding-bottom: 20px;
}
.services-img>img{
    vertical-align: middle;
}
.h4, h4 {
  font-size: 18px;
}
h2{
  margin-top: 20px;
}
.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.1;
  font-weight: 500;
}
.latest-news p {
  margin-bottom: 5px;
  line-height: normal;
  font-size: 12px;
}
.latest-news hr {
  margin: 8px;
}
p {
  margin: 0 0 10px;
}
.btn-primary {
  background-color: #2e308f;
  color:#fff;
}
h2 {
  color: #2e308f;
  margin-bottom: 20px;
  font-size: 30px;
}
.btn-primary {
  background-color: #2e308f;
     color: #fff;
    border-color: #2e6da4;
}
b{
    font-weight: 700;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
btn-primary:hover{
  color: #337ab7;;
}
`;
